/* eslint-disable react/no-unescaped-entities */
import { useCallback, useEffect, useState } from 'react'
import FoxDelegate from './FoxUI'
import { useApp } from '../../hooks/useApp'
import {
    FOX_DELEGATE_CURRENCY_MAP,
    type SupportedBlockchain,
    type SupportedToken,
} from '../../types'
import Loading from '../../components/loading/Loading'
import { ALLOWANCE_MAX_VALUE, formatBalance } from '../../utils'
import { useModal } from '../../contexts/ModalContext'
import {
    ErrorMessage,
    PostErrorMessage,
    PostOperationType,
    getErrorMessage,
} from '../../error'
import CoinSelector from '../../components/coins/CoinSelector'
import usePostMessage from '../../hooks/usePostMessage'
import { useSDK } from '@metamask/sdk-react'
import { isMobile } from 'react-device-detect'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import CloseSVG from "@mui/icons-material/Close";
import useBlockchain from '../../hooks/useBlockchain'

let strictInit = false
let isDisconnectHandled = false
let connectedAddress = ''


// eslint-disable-next-line @typescript-eslint/promise-function-async
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
async function withRetry<T> (action: () => Promise<T>, retries: number = 2): Promise<T> {
    try {
        return await action()
    } catch (e) {
        if (retries === 0) {
            throw e
        }
        await sleep(1000)
        return await withRetry(action, retries - 1)
    }
}
const FoxContainer = () => {
    /**
     * Workaround to address the disconnect issue. Without this fix, when a user disconnects from the app mainly when app is not loaded,
     * the next time they attempt to open the app, it will get stuck on the loading screen.
     * This problem occurs because the SDK makes an uncontrolled request, causing a conflict with our logic.
     * It is located here to not interfere with the rest of the app (Swaps). Should only run once when FoxContainer is rendering.
     * New behavior -> Each time that the /fox-delegate is loaded on the iframe the custom sdk modal will be shown. 
     * For some unknown reason iOS needs this workaround to be more stable. If we use this on Android it will cause an unstable connection
     */
    if (!isDisconnectHandled && !isMobile) {
        isDisconnectHandled = true
        window.localStorage.removeItem('providerType')
        /**
         * Workaround for mobile connection issues on desktop.
         * This prevents the infinite loading loop that occur when closing MM Mobile and reload the desktop app (problem 1).
         * It also handles scenarios where the desktop version gets stuck, allowing only mobile access on desktop(problem 2).
         */
        window.localStorage.removeItem('.sdk-comm')
    }
    /** STATES **/
    const [coinSelect, setCoinSelect] = useState(false)
    const [isContinuous, setContinuous] = useState(true)
    const [amount, setAmount] = useState('0.00')
    const [isAlertOpen, setAlertOpen] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [control, setControl] = useState(false)
    const [delegationProcessing, setDelegationProcessing] = useState(false)
    const [delegationSuccess, setDelegationSuccess] = useState(false)
    const [shouldRender, setShouldRender] = useState(isMobile && !control)
    const [mobileLoading, setMobileLoading] = useState(false)

    console.log("mobile control:", control)

    /** CUSTOM HOOKS **/
    const { sdk, ready, provider } = useSDK()
    const {
        selectedNetwork,
        setSelectedCurrency,
        selectedCurrency,
        blockchain,
        loadUserInfo,
        selectedWallet,
        auditThenDelegate,
        preferredFiatCurrency,
        exchangeRate,
        cardUsageTitle,
    } = useApp()

    const { getBalance } = useBlockchain()
    const { hide } = useModal()
    const { postSuccessMessage: postCloseMessage } = usePostMessage(
        PostOperationType.CLOSE
    )
    const { postCustomMessage } = usePostMessage(
        PostOperationType.WALLET_BALANCES
    )
    const ignoreErrors = ['pending', 'correct address']

    const handleReceivedMessage = (event: MessageEvent) => {
        if (event?.data?.type === 'approvalUI') {
            console.log('Received approvalUI message', event?.data)
            const isProceed = event.data.data?.action === 'proceed'
            setShouldRender(isProceed)
            setMobileLoading(!isProceed)
        }
    }
    const { postSuccessMessage, postErrorMessage } = usePostMessage(
        PostOperationType.DELEGATE_FUNDS,
        ignoreErrors,
        handleReceivedMessage
    )

    /** HANDLERS AND FUNCTIONS */
    const connectMobileMetamask = async () => {
        if (!sdk?.getProvider()) {
            setAlertOpen(true)
            setAlertMessage('MetaMask provider not ready. Please try again.')
            return
        }
        // this event must be notified every time the user clicks "Connect Metamask" from the mobile version of the delegation app
        postCustomMessage({
            type: PostOperationType.EVENT,
            data: {
                name: 'fox_connect_metamask_mobile_clicked',
            },
        })
        try {
            await blockchain.connectMetamask(selectedNetwork)
            if (connectedAddress === '') {
                // user token must be only used once
                connectedAddress = await loadUserInfo()

            }
            setMobileLoading(true)
            await withRetry(async () => { await reportBalances(connectedAddress); })
            setControl(true)
            setShouldRender(false)
        } catch (error: any) {
            postErrorMessage(
                error.message.includes(ErrorMessage.WALLET_ERROR)
                    ? PostErrorMessage.WALLET_CONNECTION_ERROR
                    : PostErrorMessage.USER_DATA,
                error
            )
            handleError(error)
        }
    }
    const coinHandler = () => {
        postCustomMessage({
            type: PostOperationType.EVENT,
            data: {
                name: 'fox_connect_token_selector_opened',
                currentToken: selectedCurrency,
            },
        })
        setCoinSelect(true)
    }
    const selectCoinHandler = async (coin: SupportedToken) => {
        if (!selectedWallet || !selectedNetwork) return
        if (coin) {
            const previousToken = selectedCurrency
            postCustomMessage({
                type: PostOperationType.EVENT,
                data: {
                    name: 'fox_connect_token_selected',
                    previousToken,
                    currentToken: coin,
                },
            })
            setSelectedCurrency(coin)
            try {
                await blockchain.refreshConnection(
                    selectedNetwork,
                    coin,
                    selectedWallet
                )
            } catch (error: any) {
                postErrorMessage(
                    PostErrorMessage.WALLET_CONNECTION_ERROR,
                    error
                )
                handleError(error)
                return
            }
        }

        setCoinSelect(false)
    }

    const delegateHandler = async (amount: string) => {
        if (!selectedCurrency) return
        try {
            setDelegationProcessing(true)

            postCustomMessage({
                type: PostOperationType.EVENT,
                data: {
                    name: 'fox_connect_approve_clicked',
                    isAutomatic: isContinuous,
                    amount,
                    token: selectedCurrency,
                },
            }
            )
            const tx = await auditThenDelegate(
                isContinuous ? ALLOWANCE_MAX_VALUE : amount
            )
            await tx.wait?.()

            postSuccessMessage()
            setDelegationProcessing(false)
            setDelegationSuccess(true)
        } catch (error) {
            postErrorMessage(PostErrorMessage.DELEGATE_FUNDS, error)
            handleError(error)
            setDelegationProcessing(false)
        } finally {
            setDelegationProcessing(false)
        }
    }

    const handleError = useCallback(
        (error: any) => {
            console.error(error)
            setAlertOpen(true)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setAlertMessage(getErrorMessage(error))
            hide()
        },
        [hide]
    )

    const reportBalances = useCallback(
        async (address: string) => {
            const tokens = [
                ...FOX_DELEGATE_CURRENCY_MAP[
                selectedNetwork as unknown as SupportedBlockchain
                ],
            ]
            const [ethBalance, balances] = await Promise.all([
                getBalance(address),
                Promise.all(tokens.map(async (token) => await blockchain.balanceOf(address, token))),
            ])
            const balanceMap = tokens.reduce((acc, token, index) => {
                acc[token] = balances[index]
                return acc
            }, {})

            const allBalances = {
                ...balanceMap,
                eth: ethBalance,
            }

            postCustomMessage({
                type: PostOperationType.WALLET_BALANCES,
                data: {
                    address,
                    balances: allBalances,
                },
            })
            // more realistic as we can check if something was wrong with the balances report
            if (
                window.location.host.includes(
                    'localhost'
                )
            ) {
                setTimeout(() => {
                    window.postMessage(
                        { type: 'approvalUI', data: { action: 'proceed' } },
                        '*'
                    )
                }, 1500)
            }
        },
        [blockchain, getBalance, postCustomMessage, selectedNetwork]
    )

    const rate =
        exchangeRate?.[selectedCurrency as string]?.[
        preferredFiatCurrency ?? ''
        ]
    const fiatBalance = rate * Number(blockchain.tokenBalance)

    const onCloseHandler = () => {
        postCloseMessage()
    }

    useEffect(() => {
        // this typeod check is required because this value can shortly be assigned to random objects
        if (blockchain.connectedAccount && typeof blockchain.connectedAccount === 'string') {
            postCustomMessage({
                type: PostOperationType.EVENT,
                data: {
                    name: 'fox_connect_selected_account',
                    address: blockchain.connectedAccount,
                },
            })
        }
    }, [blockchain.connectedAccount, postCustomMessage])

    const switchAccountHandler = async () => {
        // must be fired when the user clicks on the account selector
        postCustomMessage({
            type: PostOperationType.EVENT,
            data: {
                name: 'fox_connect_account_selector_opened',
                address: blockchain.connectedAccount,
            },
        })
        await blockchain.switchAccount()
        if (selectedNetwork && selectedCurrency && selectedWallet) {
            await blockchain.refreshConnection(
                selectedNetwork,
                selectedCurrency,
                selectedWallet
            )
        }
    }
    const showLoading =
        blockchain.isLoading ||
        !selectedNetwork ||
        (isMobile ? mobileLoading : !shouldRender)

    useEffect(() => {
        if (strictInit && blockchain.connectedAccount) {
            strictInit = false
        }
    }, [blockchain.connectedAccount, blockchain.isConnected])

    useEffect(() => {
        if (
            !selectedCurrency ||
            !selectedNetwork ||
            !selectedWallet ||
            isMobile
        ) {
            // this event must be notified every time the app is opened
            // it will be spammed if put in the main useEffect
            postCustomMessage({
                type: PostOperationType.EVENT,
                data: {
                    name: 'fox_connect_opened',
                },
            })
            return
        }

        if (
            sdk &&
            provider &&
            ready &&
            !strictInit &&
            !blockchain.connectedAccount
        ) {
            // this event must be notified every time the app is opened
            // it will be spammed if put in the main useEffect
            postCustomMessage({
                type: PostOperationType.EVENT,
                data: {
                    name: 'fox_connect_opened',
                },
            })
            strictInit = true
            void blockchain
                .connectMetamask(selectedNetwork)
                .then(loadUserInfo)
                .then(reportBalances)
                .catch((error) => {
                    console.error('error:', error)
                    postErrorMessage(
                        error.message.includes(ErrorMessage.WALLET_ERROR)
                            ? PostErrorMessage.WALLET_CONNECTION_ERROR
                            : PostErrorMessage.USER_DATA,
                        error
                    )
                    handleError(error)
                })
        }
    }, [blockchain, blockchain.connectedAccount, handleError, loadUserInfo, postCustomMessage, postErrorMessage, provider, ready, reportBalances, sdk, selectedCurrency, selectedNetwork, selectedWallet])

    return (
        <div style={{ padding: "1.5rem" }}>
            {(coinSelect && selectedNetwork && (
                <CoinSelector
                    coins={FOX_DELEGATE_CURRENCY_MAP[selectedNetwork]}
                    setSelectedCurrency={selectCoinHandler}
                    selectedNetwork={selectedNetwork}
                />
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            )) || (
                    <>
                        <Grid rowGap={"1.5rem"} alignItems={'center'} item xs={12} container>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                                <Typography align="center" variant="h5">
                                    {!showLoading && 'Set a spending limit'}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={1}
                                container
                                justifyContent="end"
                            >
                                <IconButton
                                    color="inherit"
                                    onClick={onCloseHandler}
                                >
                                    <CloseSVG />
                                </IconButton>
                            </Grid>
                        </Grid>
                        {(showLoading && <Box height="100vh"><Loading /></Box>) || (
                            <>
                                <FoxDelegate
                                    alertText={alertMessage}
                                    openAlert={isAlertOpen}
                                    handleCloseAlert={() => {
                                        setAlertMessage('')
                                        setAlertOpen(false)
                                        hide()
                                    }}
                                    coinHandler={coinHandler}
                                    accountId={
                                        blockchain.connectedAccount ?? ''
                                    }
                                    selectedCurrency={selectedCurrency as any}
                                    selectedNetwork={selectedNetwork as any}
                                    fiatBalance={formatBalance(
                                        fiatBalance.toString()
                                    )}
                                    tokenBalance={formatBalance(
                                        blockchain.tokenBalance
                                    )}
                                    delegate={delegateHandler}
                                    isContinuous={isContinuous}
                                    setContinuous={setContinuous}
                                    amount={amount}
                                    setAmount={setAmount}
                                    preferredFiatCurrency={
                                        preferredFiatCurrency
                                    }
                                    cardUsageTitle={cardUsageTitle}
                                    control={control}
                                    connectMobileMetamask={
                                        connectMobileMetamask
                                    }
                                    delegationProcessing={delegationProcessing}
                                    delegationSuccess={delegationSuccess}
                                    switchAccountHandler={switchAccountHandler}
                                    allowance={blockchain.allowance}
                                />
                            </>
                        )}
                    </>
                ) || <></>}
        </div>
    )
}

export default FoxContainer
