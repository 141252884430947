import { type ReactElement, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from '../../components/loading/Loading'
import { useApp } from '../../hooks/useApp'
import { useModal } from '../../contexts/ModalContext'
import {
    ErrorMessage,
    PostErrorMessage,
    PostOperationType,
    getErrorMessage,
    handleError,
} from '../../error'
import {
    useSwapToken,
    useToken,
    useWalletToken,
} from '@baanx/common/network/api/token'
import {
    AppMode,
    WALLET_MAP,
    ANRK_SWAP_CURRENCY_MAP,
} from '../../types'
import usePostMessage from '../../hooks/usePostMessage'
import config from '../../config'
import SignHome from './SignHome'
import { type SwapData } from '@baanx/domain'

function Sign(): ReactElement {
    const [address, setAddress] = useState('')
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const [swapInfo, setSwapInfo] = useState<SwapData>({} as unknown as any)
    const { showPersistentModal } = useModal()
    const {
        setAppMode,
        setSelectedNetwork,
        setSelectedCurrency,
        setSelectedWallet,
        setUserId,
        blockchain,
        selectedCurrency,
        selectedNetwork,
        selectedWallet,
    } = useApp()

    const location = useLocation()
    const navigate = useNavigate()

    const swapToken = new URLSearchParams(location.search).get('swapToken')
    const userToken = new URLSearchParams(location.search).get('userToken')
    const skipConfirmation = new URLSearchParams(location.search).get(
        'skipConfirmation'
    )

    const { refetch: getWalletToken, isFetched } = useWalletToken(config)
    const { refetch: getSwapToken } = useSwapToken(config, swapToken as string)
    const { refetch: getUserInfo } = useToken(config, {
        token: userToken as string,
    })

    const { postErrorMessage } = usePostMessage(
        PostOperationType.SIGN_TRANSACTION
    )
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setAppMode(AppMode.SIGN)
        if (isFetched) return
        setIsLoading(true)

        // validate swap token
        if (!swapToken) {
            showPersistentModal('Error', ErrorMessage.INVALID_LINK)
            return
        }

        void (async () => {
            try {
                const { data: swapData } = await getSwapToken()
                const { data: walletData } = await getWalletToken()
                // Handle registered user
                if (userToken) {
                    const { data: userData } = await getUserInfo()
                    if (!userData) {
                        postErrorMessage(PostErrorMessage.USER_DATA)

                        throw Error(ErrorMessage.USER_DATA_ERROR)
                    }
                    setUserId(userData.userId)
                }

                if (!swapData) {
                    postErrorMessage(PostErrorMessage.SWAP_DATA)

                    throw Error(ErrorMessage.SWAP_DATA_ERROR)
                }
                setSelectedNetwork(swapData.from.blockchain)
                setSelectedCurrency(swapData.from.token)

                if (!walletData) {
                    postErrorMessage(PostErrorMessage.WALLET_NOT_CONNECTED)

                    return
                }
                if (
                    !ANRK_SWAP_CURRENCY_MAP[swapData.from.blockchain].includes(
                        swapData.from.token
                    )
                ) {
                    showPersistentModal(
                        'Error',
                        `Token ${swapToken} not supported`
                    )
                    return
                }

                if (
                    !WALLET_MAP[swapData.from.blockchain].includes(
                        walletData.wallet.provider
                    )
                ) {
                    showPersistentModal(
                        'Error',
                        `Wallet ${walletData.wallet.provider} not supported`
                    )
                }


                setSelectedWallet(walletData.wallet.provider)
                setAddress(walletData.wallet.address)
                setSwapInfo(swapData)
    
            } catch (error: any) {
                showPersistentModal('Error', getErrorMessage(error))
                handleError(error)
            } finally {
                setIsLoading(false)
            }
        })()
    }, [getSwapToken, getUserInfo, getWalletToken, isFetched, navigate, postErrorMessage, setAppMode, setSelectedCurrency, setSelectedNetwork, setSelectedWallet, setUserId, showPersistentModal, skipConfirmation, swapToken, userToken])

    return (
        (isLoading && <Loading />) || (
            address && <SignHome
                selectedCurrency={selectedCurrency}
                selectedWallet={selectedWallet}
                selectedNetwork={selectedNetwork}
                blockchain={blockchain}
                address={address}
                swapData={swapInfo}
                skipConfirmation={!!skipConfirmation}
            ></SignHome>
        ) || <></>
    )
}

export default Sign
